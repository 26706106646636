

import Vue, { PropType } from 'vue';
import { PRODUCT_KEY_NAME_RAS, STORE_NAME_AAS, STORE_NAME_GPS } from '@core/constants/subscriptions';
import { KB_LINK_EXTERNAL_BUNDLE } from '@core/constants/links';
import Subscription, {
  STATUS_ACTIVE,
  STATUS_BLACKLISTED,
  STATUS_EXPIRED,
  STATUS_SUSPENDED
} from '@/models/subscription';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import PaymentOptionsLink from '@/modules/subscriptions/paymentOptions/paymentOptionsLink.vue';
import ConfigureAppleAppStoreLink from '@/modules/subscriptions/configureAppleAppStore/configureAppleAppStoreLink.vue';
import ConfigureGooglePlayStoreLink
  from '@/modules/subscriptions/configureGooglePlayStore/configureGooglePlayStoreLink.vue';

export default Vue.extend({
  name: 'subscription-status',
  mixins: [ComponentMixIn, SubscriptionMixIn],
  components: { PaymentOptionsLink, ConfigureAppleAppStoreLink, ConfigureGooglePlayStoreLink },

  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
    serviceSubscription: {
      type: Object as PropType<{ isOfflineAllowed: boolean }>,
    },
    canEdit: {
      type: Boolean,
    },
    equalTo: {
      type: String,
    },
    notificationIsHidden: {
      type: Boolean,
      default: false,
    },
    isSubscriptionUpgrading: {
      type: Boolean,
      default: false,
    },
  },

  mounted (): void {
    this.$bus.$on('notificationOpen', this.onNotificationOpen);
    this.$bus.$on('notificationClose', this.onNotificationClose);
  },

  destroyed (): void {
    this.$bus.$off('notificationClose', this.onNotificationOpen);
    this.$bus.$off('notificationOpen', this.onNotificationClose);
  },

  data () {
    return {
      KB_LINK_EXTERNAL_BUNDLE,
      STORE_NAME_AAS,
      STORE_NAME_GPS,
      statusMap: new Map([
        [STATUS_ACTIVE, 'Active'],
        [STATUS_BLACKLISTED, 'Blacklisted'], // Does not return from server, just in case
        [STATUS_SUSPENDED, 'Suspended'],
        [STATUS_EXPIRED, 'Expired'],
      ]),
      subscriptionRenewalLabel: '',
      subscriptionRenewalText: '',
      subscriptionRenewalButtonText: '',
      subscriptionRenewalButtonAction: null as Function,
      nextBillingDateLoading: false,
      nextBillingDate: null as Date,
      showButtonLoader: false,
    };
  },

  methods: {
    initFields (): void {
      //
      // Translation strings for subscriptionRenewalLabel, subscriptionRenewalText and
      // subscriptionRenewalButtonText
      //
      // $t('Subscription Renewal')
      // $t('Type')
      // $t('Subscription')
      // $t('Permanent License')
      // $t('SPLA')
      // $t('Renew Subscription')
      // $t('Manage Subscription')
      // $t('Turn On Auto Renewal')
      // $t('Enable Auto Renewal')
      //
      const s = this.subscription;
      this.subscriptionRenewalLabel = 'Subscription Renewal';
      this.subscriptionRenewalText = '';
      this.subscriptionRenewalButtonText = '';
      this.subscriptionRenewalButtonAction = null;
      this.showButtonLoader = false;
      this.nextBillingDate = s.nextBillingDate;
      if (s.isRasAzmp) {
        this.subscriptionRenewalLabel = 'Billing Type';
        const baseText = 'Azure MP, ';
        if (s.isPostPaid) {
          this.subscriptionRenewalText = `${baseText} metered`;
        } else {
          this.subscriptionRenewalText = `${baseText} fixed`;
        }
      } else if (s.isBytebotStore) {
        this.subscriptionRenewalLabel = 'Type';
        this.subscriptionRenewalText = 'Subscription';
      } else if (s.isPermanent) {
        this.subscriptionRenewalLabel = 'Type';
        this.subscriptionRenewalText = 'Permanent License';
      } else if (s.isPostPaid) {
        this.subscriptionRenewalLabel = 'Type';
        this.subscriptionRenewalText = 'SPLA';
      } else if ((s.isExpired || s.isGracePeriod) && s.isSellOptionApplicable) {
        this.subscriptionRenewalButtonText = 'Renew Subscription';
        this.subscriptionRenewalButtonAction = () => {
          this.$emit('renewClick');
        };
      } else if (s.isUpgrading) {
        this.setProcessingRenewalStatus();
      } else if (s.isAutorenewable) {
        this.subscriptionRenewalButtonText = 'Manage Subscription';
        this.subscriptionRenewalButtonAction = () => {
          this.$emit('manageSubscriptionClick');
        };
      } else if (s.canTurnOnAutorenew) {
        this.subscriptionRenewalButtonText = 'Turn On Auto Renewal';
        this.subscriptionRenewalButtonAction = () => {
          this.$emit('autoRenewClick', true);
        };
      } else if (s.isSellOptionApplicable) {
        this.subscriptionRenewalButtonText = 'Enable Auto Renewal';
        this.subscriptionRenewalButtonAction = () => {
          this.$emit('renewClick');
        };
      }
    },

    notificationIconClickAction (): void {
      this.$bus.$emit('notificationOpen', { name: 'subscriptionNotification' });
    },

    onNotificationOpen ({ name }): void {
      if (name === 'subscriptionNotification') {
        this.$set(this, 'notificationIsHidden', false);
      }
    },

    onNotificationClose ({ name }) {
      if (name === 'subscriptionNotification') {
        this.$set(this, 'notificationIsHidden', true);
      }
    },
    setProcessingRenewalStatus () {
      this.subscriptionRenewalButtonText = 'Processing...';
      this.showButtonLoader = true;
      this.subscriptionRenewalButtonAction = () => {
        this.$emit('pendingClick');
      };
    },
  },

  computed: {
    status (): string {
      return this.$t(this.statusMap.get(this.subscription.status));
    },

    statusColor (): string {
      const status = this.subscription.status;
      if (status === STATUS_ACTIVE) {
        return 'text-success';
      }
      if ([STATUS_EXPIRED, STATUS_SUSPENDED, STATUS_BLACKLISTED].indexOf(status) > -1) {
        return 'text-danger';
      }
      return '';
    },

    showExpirationDate (): boolean {
      const s = this.subscription;
      return !s.isPermanent && !s.isAutorenewable && !!s.expirationDate && !s.isNeverExpiringSPLA;
    },

    showNextBillingDate (): boolean {
      return this.subscription.isAutorenewable || (this.subscription.isPostPaid && this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS));
    },

    showRenewFee (): boolean {
      return this.subscription.isAutorenewable && this.subscription.nextBillingCost && this.canEdit;
    },

    renewalFeeText (): string {
      if (!this.showRenewFee) {
        return '';
      }
      return [this.subscription.nextBillingCostCurrency, this.subscription.nextBillingCost].join(' ');
    },

    hasOrdersHistory (): boolean {
      return this.subscription.isPrimary;
    },

    isOfflineAllowed (): boolean {
      return this.serviceSubscription && this.serviceSubscription.isOfflineAllowed;
    },
  },

  watch: {
    subscription (value: Subscription): void {
      if (value) {
        this.initFields();
      }
    },
    isSubscriptionUpgrading (value: boolean): void {
      if (value) {
        this.setProcessingRenewalStatus();
      }
    },
  },

  created (): void {
    this.initFields();
  },

});

