/**
 * File: deleteExternalDomainGroup.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import Service from '@core/constants/services';

interface DeleteExternalDomainGroupParams {
  'external_id': string;
}

export default class DeleteExternalDomainGroup extends ApiRequest<DeleteExternalDomainGroupParams> {
  get method () {
    return 'DELETE' as const;
  }

  get url () {
    return `/${Service.Account}/api/v2/external_auths/external_domain_groups/${encodeURIComponent(this.params.external_id)}`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
