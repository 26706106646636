/**
 * File: subscriptionDetailsContent.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';
import { copyExcluding } from '@core/common/utils';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionRequest from '@/api/subscriptionRequest';
import RasSubscriptionRequest from '@/api/rasSubscriptionRequest';
import SubscriptionUpdateRequest from '@/api/subscriptionUpdateRequest';
import SubscriptionAutoRenewalUpdateRequest from '@/api/subscriptionAutoRenewalUpdateRequest';
import SubscriptionPollingRequest from '@/api/subscriptionPollingRequest';
import SubscriptionMixIn from '../subscriptionMixIn';
import Products from './products/products.vue';
import Status from './status/status.vue';
import Properties from './properties/properties.vue';
import UpgradedSupport from './upgraded_support/upgradedSupport.vue';
import LicenseKeys from './licenseKeys/licenseKeys.vue';
import OrdersHistoryModal from '../orders/ordersHistoryModal.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import ContractDetailsModal from '../contract/contractDetailsModal.vue';
import RenewSubscriptionModal from '../renewal/renewSubscriptionModal.vue';
import PaymentOptionsModal from '../paymentOptions/paymentOptionsModal.vue';
import ConfigureAppleAppStoreModal from '../configureAppleAppStore/configureAppleAppStoreModal.vue';
import ConfigureGooglePlayStoreModal from '../configureGooglePlayStore/configureGooglePlayStoreModal.vue';
import ConfirmRedirectModal from '@/ui/confirmRedirectModal/index.vue';
import Subscription, { VIEW_STATUS } from '@/models/subscription';
import {
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_RAS_AZMP,
  STORE_NAME_BYTEBOT
} from '@core/constants/subscriptions';
import GetBytebotStatisticsRequest from '@/api/getBytebotStatisticsRequest';
import { RENEW_ONLINE_PAGE } from '@/routes/constants';
import EditPropertiesModal from '@/modules/subscriptions/details/editPropertiesModal/editPropertiesModal.vue';
import RenewalCancelledNotificationModal
  from '@/modules/subscriptions/details/renewalNotificationModal/renewalNotificationModal.vue';
import GetSubscriptionCBData, { GetSubscriptionCBDataResponse } from '@/api/getSubscriptionCBData';
import Service from '@core/constants/services';

type LoadOptions = {
  updating?: boolean;
  woKeys?: boolean;
};

export type ContractDetailsModalTabEvent = { tab?: 'general' | 'sales' }

const MODAL_ORDERS_HISTORY = 'ordersHistory';
const MODAL_CONTRACT_DETAILS = 'contractDetails';
const MODAL_RENEW_SUBSCRIPTION = 'renewSubscription';

const CARD_KEY_NAME = 'subscription-details-card';

export default Vue.extend({
  name: 'subscription-details-content',

  mixins: [ComponentMixIn, SubscriptionMixIn],

  components: {
    Products,
    Status,
    UpgradedSupport,
    LicenseKeys,
    OrdersHistoryModal,
    AddLicensesModal,
    ContractDetailsModal,
    RenewSubscriptionModal,
    PaymentOptionsModal,
    ConfigureAppleAppStoreModal,
    ConfigureGooglePlayStoreModal,
    ConfirmRedirectModal,
    Properties,
    EditPropertiesModal,
    RenewalCancelledNotificationModal,
  },

  props: {
    productKey: {
      type: String,
    },
    notificationIsHidden: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      loading: true,
      updating: false,
      isSubscriptionUpgrading: false,
      subscription: null as Subscription,
      serviceSubscription: null,
      newSubscriptionName: '',
      isNameUpdating: false,
      personalMode: this.$appData.session.isPersonalUser(this.$appData.userInDomain),
      cardKeyName: CARD_KEY_NAME,
    };
  },

  created () {
    this.load();
  },
  watch: {
    id () {
      this.load();
    },
    updating (updating) {
      this.cardKeyName = updating ? '' : CARD_KEY_NAME;
    },
    subscription (subscription) {
      this.$modal.hide();
      if (subscription && subscription.isUpgrading) {
        this.sendSubscriptionPollingRequest();
      }
    },
  },

  methods: {
    load (options = {} as LoadOptions) {
      if (this.isRas) {
        this.loadRas(options);
      } else {
        this.loadAny(options);
      }
    },

    reload (options: LoadOptions) {
      options = options || { woKeys: false };

      this.load({ updating: true });

      if (!options.woKeys) {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.licenseKeys.load();
      }
    },

    updateSubscription (newData: Subscription) {
      const clone = Object.assign({}, newData);
      Object.setPrototypeOf(clone, Subscription.prototype);
      this.subscription = clone;
    },

    async loadAny (options: LoadOptions) {
      const request = new SubscriptionRequest({ uuid: this.id });

      if (options.updating) {
        request.dropCache();
      }

      const loadingField = options.updating ? 'updating' : 'loading';
      this[loadingField] = true;

      try {
        const request = new SubscriptionRequest({ uuid: this.id });
        await this.$api.authorizedCall(request);
        this.subscription = request.getSubscription();
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.newSubscriptionName = this.subscriptionName;
        this.$emit('subscriptionLoaded', this.subscription);
        await this.loadBytebotStatistics();
      } catch (e) {
        this.notFound();
      } finally {
        this[loadingField] = false;
        this.handleQuery();
      }
    },

    async loadRas (options: LoadOptions) {
      const combinedRequest = new CombinedApiRequest();
      const lsSubscriptionRequestName = 'lsSubscription';
      const rasSubscriptionRequestName = 'rasSubscription';
      const lsSubscriptionRequest = new SubscriptionRequest({ uuid: this.id });
      const rasSubscriptionRequest = new RasSubscriptionRequest({ uuid: this.id });
      combinedRequest.addRequest(lsSubscriptionRequestName, lsSubscriptionRequest);
      combinedRequest.addRequest(rasSubscriptionRequestName, rasSubscriptionRequest);

      if (options.updating) {
        lsSubscriptionRequest.dropCache();
        rasSubscriptionRequest.dropCache();
      }

      const loadingField = options.updating ? 'updating' : 'loading';
      this[loadingField] = true;

      try {
        await this.$api.authorizedCall(combinedRequest);
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
        this.subscription = combinedRequest.getRequest(lsSubscriptionRequestName).getSubscription();
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
        this.serviceSubscription = combinedRequest.getRequest(rasSubscriptionRequestName).getSubscription();
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.newSubscriptionName = this.subscriptionName;
        this.$emit('subscriptionLoaded', this.subscription);
      } catch (e) {
        this.notFound();
      } finally {
        this[loadingField] = false;
        this.handleQuery();
      }
    },

    handleQuery () {
      if (this.$route.query.modal === MODAL_ORDERS_HISTORY) {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$nextTick(() => this.$refs.ordersHistoryModal.show());
      } else if (this.$route.query.modal === MODAL_CONTRACT_DETAILS) {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$nextTick(() => this.$refs.contractDetailsModal.show());
      }
      if (this.$route.query.checkLicenseProperties) {
        this.$router.replace({ query: copyExcluding(this.$route.query, ['checkLicenseProperties']) });
        this.checkLicenseProperties();
      }
      if (this.$route.query.turnAutoRenewOn) {
        this.$router.replace({ query: copyExcluding(this.$route.query, ['turnAutoRenewOn']) });
        if (this.subscription.canRenew && !this.subscription.isAutorenewable) {
          this.onAutoRenewClick();
        }
      } else if (this.$route.query.renew) {
        this.$router.replace({ query: copyExcluding(this.$route.query, ['renew']) });
        this.onRenewClick();
      }
    },

    saveNewName () {
      const request = new SubscriptionUpdateRequest({
        uuid: this.id,
        name: this.newSubscriptionName,
        service: this.subscription.targetServiceName,
      });
      this.authorizedCall(request, 'isNameUpdating')
        .then(() => {
          this.subscription.name = this.newSubscriptionName;
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          this.newSubscriptionName = this.subscriptionName;
        });
    },

    loadBytebotStatistics () {
      if (this.subscription.storeId !== STORE_NAME_BYTEBOT) return;

      const request = new GetBytebotStatisticsRequest({ subscriptionUuid: this.subscription.uuid });
      return this.$api.authorizedCall(request)
        .then(() => {
          this.subscription.setByteBotStatisticsInfo(request.data);
        });
    },

    saveAutoRenewalState (active) {
      if (this.subscription instanceof Subscription) {
        const request = new SubscriptionAutoRenewalUpdateRequest({
          uuid: this.id,
          service: this.subscription.targetServiceName,
          autorenew: active,
        });
        this.isSubscriptionUpgrading = true;
        this.$modal.show(RenewalCancelledNotificationModal);
        return this.authorizedCall(request, null)
          .then(() => {
            this.sendSubscriptionPollingRequest();
          });
      } else {
        this.showToast($t('Can\'t change subscription state.'), 'orange');
      }
    },

    sendSubscriptionPollingRequest () {
      const subscriptionPollingRequest = new SubscriptionPollingRequest({
        uuid: this.id,
        initialTimeout: 1000,
        service: this.subscription.targetServiceName,
      });
      return this.authorizedCall(subscriptionPollingRequest, null)
        .then(() => {
          this.isSubscriptionUpgrading = false;
          this.subscription = subscriptionPollingRequest.getSubscription();
        });
    },

    onAddMoreLicensesClick () {
      this.$modal.show(AddLicensesModal, {
        product: this.subscription.firstProductKeyName,
        subscriptionUuid: this.subscription.uuid,
      });
    },

    onAutoRenewClick () {
      this.saveAutoRenewalState(true);
    },

    async onManageSubscriptionClick () {
      try {
        const request = new GetSubscriptionCBData({
          uuid: this.id,
          service: this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS) ? Service.RasLicense : Service.License,
        });
        const data: GetSubscriptionCBDataResponse = await this.authorizedCall(request, null);
        if (data.extKey && data.sKey) {
          window.open(`https://www.parallels.com/subscription-cancellation/?sid=${data.extKey}&skey=${data.sKey}`, '_blank');
        } else {
          throw new Error('No extKey and sKey received');
        }
      } catch (e) {
        this.$toast.show({
          text: $t('There was a problem completing this request.'),
          color: 'red',
        });
      }
    },

    clearModalQuery () {
      this.$router.replace({ query: copyExcluding(this.$route.query, ['modal']) });
    },

    onRenewClick () {
      if (this.subscription.viewStatus === VIEW_STATUS.SF_EXPIRED && !this.subscription.isGracePeriod) {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.renewSubscriptionModal.show();
      } else {
        const route = {
          name: RENEW_ONLINE_PAGE,
          params: {
            id: this.subscription.uuid,
            byRef: true,
          },
        };
        // @ts-ignore
        this.$router.push(route);
      }
    },

    onRenewSubscriptionShow () {
      this.$router.replace({ query: Object.assign({}, this.$route.query, { modal: MODAL_RENEW_SUBSCRIPTION }) });
    },

    onRenewSubscriptionHide () {
      this.clearModalQuery();
    },

    onOrdersHistoryClick () {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.ordersHistoryModal.show();
    },

    onOrdersHistoryHide () {
      this.clearModalQuery();
    },

    onOrdersHistoryShow () {
      this.$router.replace({ query: Object.assign({}, this.$route.query, { modal: MODAL_ORDERS_HISTORY }) });
    },

    onContractDetailsClick (event: ContractDetailsModalTabEvent) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.contractDetailsModal.show(event);
    },

    onContractDetailsHide () {
      this.clearModalQuery();
    },

    onContractDetailsShow () {
      this.$router.replace({ query: Object.assign({}, this.$route.query, { modal: MODAL_CONTRACT_DETAILS }) });
    },

    notFound () {
      // this.$router.replace({ name: 'subscriptions'});
      // this.error = true;
      this.$emit('notFound');
    },

    onContactParallelsClick () {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.contractDetailsModal.show({ tab: 'sales' });
    },

    onPendingClick () {
      this.$modal.show(RenewalCancelledNotificationModal);
    },

    onOpenChangePropertiesModal (withDescription?: boolean) {
      this.$modal.show(EditPropertiesModal, this.subscription, withDescription);
    },
    checkLicenseProperties () {
      const openModalCondition = !this.subscription.traits?.awgCustomer || !this.subscription.traits?.awgPartner;
      if (this.isShowProperties && openModalCondition) {
        this.$nextTick(() => this.onOpenChangePropertiesModal(true));
      }
    },
  },
  computed: {
    isShowProperties (): boolean {
      return this.subscription.hasProduct(PRODUCT_KEY_NAME_PSW) ||
        this.subscription.hasProduct(PRODUCT_KEY_NAME_DAAS) ||
        this.subscription.hasProduct(PRODUCT_KEY_NAME_PBI) ||
        this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS_AZMP);
    },
    hasLicenseKeys (): boolean {
      return this.subscription && this.subscription.universalKeys.length && !this.subscription.isBytebotStore;
    },
    canEditSubscription (): boolean {
      return this.$appData.session.isPersonalUser(this.$appData.userInDomain) || this.$appData.session.isBusinessAdmin;
    },
    isRas (): boolean {
      return this.productKey === PRODUCT_KEY_NAME_RAS;
    },
    isUpgradedSupportAvailable (): boolean {
      return this.isRas && this.serviceSubscription?.upgradedSupport.type;
    },
    id (): string {
      return this.$route.params.id;
    },
    subscriptionType (): string {
      // @ts-ignore FIXME https://parallels.atlassian.net/browse/CPCLOUD-21007
      // it's workaround now. use isTrial flag after this ticket will be done
      const isTrial = this.subscription.storeProducts.some(p => p.details?.extKey.toLowerCase().includes('trial'));
      if (isTrial) {
        return this.$t('Trial');
      }
      if (this.subscription.isPermanent) {
        return this.$t('Permanent License');
      }
      if (this.subscription.isPostPaid) {
        return this.$t('SPLA');
      }
      return this.$t('Subscription');
    },
  },
});
