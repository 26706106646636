/**
 * File: externalDomainGroupsAutomap.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import Service from '@core/constants/services';

interface ExternalDomainGroupsAutomapBody {
  name: string;
  'external_id': string;
  'permission_uuid'?: string;
}

interface ExternalDomainGroupAutomapResponse {
  name: string;
  'external_id': string;
  'associated_groups': string[];
}

export default class ExternalDomainGroupAutomap extends ApiRequest<ExternalDomainGroupsAutomapBody, ExternalDomainGroupAutomapResponse> {
  get method () {
    return 'POST' as const;
  }

  get url () {
    return `/${Service.Account}/api/v2/external_auths/external_domain_groups/create_with_automap`;
  }

  get body () {
    return {
      name: this.params.name,
      external_id: this.params.external_id,
      permission_uuid: this.params.permission_uuid,
    };
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
