/**
 * File: domainsGroupPermissions.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import Service from '@core/constants/services';

interface DomainsGroupsPermissionsParams {
  limit?: number;
  offset?: number;
}

export interface DomainsGroupsPermission {
  uuid: string;
  'display_name': string;
  'code_name': string;
}

interface DomainsGroupsPermissionsResponse {
  count: number;
  next: string;
  previous: string;
  results: DomainsGroupsPermission[];
}

export default class DomainsGroupPermissions extends ApiRequest<DomainsGroupsPermissionsParams, DomainsGroupsPermissionsResponse> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return `/${Service.Account}/api/v2/domains/group_permissions`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}
