/**
 * File: getSubscriptionCBData.ts
 *
 * Copyright:
 * Copyright © 2025 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';

interface GetSubscriptionCBDataParams {
  uuid: string;
  service: string;
}

export interface GetSubscriptionCBDataResponse {
  extKey: string;
  customerId: number;
  customerCurrencyId: string;
  sKey: string;
}

export default class GetSubscriptionCBData extends ApiRequest<GetSubscriptionCBDataParams, GetSubscriptionCBDataResponse> {
  get url (): string {
    return `/${this.params.service}/api/v1/subscriptions/${this.params.uuid}/cb_info`;
  }

  get method (): Method {
    return 'GET';
  }
}
